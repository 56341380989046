import { IPopupOrder, IProduct } from "../../@types/interface";
import Image from 'next/image';
import { useEffect, useRef, useState } from "react";
import environment from "../../utils/environment";

const PopupSizeGuide = ({
    setIsOpen,
    embed
}: any) => {

    let wrapperRef: any = useRef();
    let src_embed = (embed ? embed : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.7839451104583!2d106.66719497491994!3d10.751127889396095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752ef936e4e0ab%3A0x228f749bc8da87ef!2zOCDEkC4gTmd1eeG7hW4gVGjhu51pIFRydW5nLCBQaMaw4budbmcgNSwgUXXhuq1uIDUsIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1741917319020!5m2!1svi!2s");
    const handleClick = (event: any) => {
        const { target } = event
        if (!wrapperRef.current.contains(target)) {
            setIsOpen({
                isOpen: false,
                embed: ""
            })
        }
    }

    return (
        <div id="map_popup" className="wrap_popup" onClick={handleClick}>
            <div ref={wrapperRef} className="map_pop m-2 lg:m-o lg:max-w-[800px] flex-col relative">
                {/* <a className="close" onClick={() => { setIsOpen(false) }}>&times;</a> */}
                {
                    <iframe src={src_embed} width="100%" height="100%" loading="lazy" ></iframe>
                }
                {/* {
                    coordinates &&
                        <Map
                            defaultZoom={13}
                            defaultCenter={{
                                lat: coordinates.latitude,
                                lng: coordinates.longitude,
                              }}
                        >
                            <Marker
                                position={{
                                    lat: coordinates.latitude,
                                    lng: coordinates.longitude,
                                }}
                            />
                        </Map>
                }
                 */}
                <Image
                    onClick={() => setIsOpen({
                        isOpen: false,
                        embed: ""
                    })}
                    src={"/asset/icon/ico_close_circle.svg"}
                    alt="close"
                    className="w-[24px] h-[24px] lg:w-[40px] lg:h-[40px] absolute top-[-10px] lg:top-[-15px] left-[96%] lg:left-[97%] z-[1000]"
                    layout="fill"
                    objectFit="cover"
                />
            </div>

        </div>
    )
}

export default PopupSizeGuide;