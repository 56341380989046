import dynamic from "next/dynamic";
import { useRef, useState } from "react";

const DynamicItem = dynamic(() => import('react-player'));

interface VideoPlayerProps {
    url: string;
    className: string;
    id?: string,
    ref?: any,
    autoPlay?: boolean,
    muted?: boolean
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    url,
    className,
    id,
    ref,
    autoPlay=false,
    muted=false
}) => {
    const [onPlay, setOnPlay] = useState(autoPlay);

    return (
        <>
            <div onClick={() => { setOnPlay(!onPlay) }} className='iframe-container-div h-full w-full flex md:hidden'>
                <DynamicItem
                    muted={muted}
                    playing={onPlay}
                    controls={true} 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    className={className + " max-h-[70vh] lg:max-h-full "}
                    url={url} />
            </div>
            <DynamicItem
                ref={ref}
                id={id ? id : "vid_"}
                muted={muted}
                controls={true} 
                playing={onPlay}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                className={className + " max-h-[70vh] lg:max-h-full hidden md:flex"}
                url={url} />
        </>


    );
}

export default VideoPlayer;