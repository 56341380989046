import { NextApiRequest, NextApiResponse } from "next";
import apiModule from "../../utils/api";
import { baseURL, getUrls } from "./hello";

export const config = {
    runtime: 'edge',
};

export const getProductDetail = async (id: number) => {
    let response = await apiModule.get(baseURL + "/landing-page/get-product-detail?product_detail_id=" + id);
    if (response) {
        return response.data;
    }
}

export const getProductDetailMix = async (string: string) => {
    let response = await apiModule.get(baseURL + "/landing-page/get-product-detail" + string);
    if (response) {
        return response.data;
    }
}

export const getProductDetailByUrl = async (url: string) => {
    let response = await apiModule.get(baseURL + "/landing-page/get-product-detail-by-url?url=" + url);
    if (response) {
        return response.data;
    }
}

export const postTryOnSchedule = async (data: any) => {
    let response = await apiModule.post(baseURL + "/landing-page/try-on-schedule", data);
    if (response) {
        return response.data;
    }
}

export const getRefreshStockingCount = async (product_code: string, product_details: string, from_date: string, to_date: string, is_mix: boolean, amount: any, data: any, index: any = 0) => {
    //console.log('from_date', from_date);
    //console.log('to_date', to_date);
    let response = await apiModule.post(baseURL + `/product/refresh-stocking-count-v2?product_code=${product_code}${from_date ? '&from_date=' + from_date : ''}${to_date ? '&to_date=' + to_date : ''}&is_mix=${is_mix}&index=${index}&amount=${amount}&product_details=${product_details}`, { related_products: data });
    if (response) {
        return response.data;
    }
}

// export default async function handler(req: NextApiRequest, res: NextApiResponse) {
//     const urlRequest: any = await getUrls() ?? [];
//     res.status(200).json(urlRequest)
// }

export const getProductDetailCombo = async (code: string) => {
    let response = await apiModule.get(baseURL + "/landing-page/get-product-mix-by-product?code=" + code);
    if (response) {
        return response.data;
    }
}

export const getProductDetailByPdm = async (data: any) => {
    let response = await apiModule.post(baseURL + "/landing-page/get-product-detail-by-pdm", data);
    if (response) {
        return response.data;
    }
}

export const getInventoryProductDetailByCode = async (product_code: string, from_date?: string, to_date?: string) => {
    try{
        let response = await apiModule.get(baseURL + `/landing-page/get-product-branch?product_detail_code=${product_code}${from_date ? '&from_date=' + from_date : ''}${to_date ? '&to_date=' + to_date : ''}`);
        if (response) {
            return response.data;
        }
    }catch(e:any)
    {
        return null;
    }
}