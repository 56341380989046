import Image from 'next/image';
import environment from '../../utils/environment';
import { checkUndefinedNumber, currencyWithNoFormat } from '../../utils/format';
import InputNumber from '../Input/InputNumber';
import { AttributeValue } from '../AttributeValues';
import Link from 'next/link';

const ProductItem = ({ is_product, item, ...props }: any) => {
    let img_url = (is_product == true ? item?.files[0]?.file_url : item?.file?.file_url);
    let src_img = (img_url ? environment.baseImageUrl + img_url : "/asset/image/test_product_tab.svg" );

    function onActive(id: number) {
        return item?.attribute?.find((e: any) => e.value === id) ?? false;
    }

    const Attributes = () => {
        return <>
            {
                props?.attribute && props?.attribute[item.product_code]?.map((attr: any) => {
                    return <AttributeValue onActive={onActive} key={attr.id} item={attr} handleChangeActive={(id: any, value: any) => {
                        props?.handleChangeActive(item.id, {
                            id: id,
                            value: value
                        })
                    }} />
                })
            }
        </>
    }

    function checkDisableInput() {
        let result = false;
        // if (checkUndefinedNumber(item?.amount) === 0) {
        //     result = true;
        // } else {
        //     result = false;
        // }
        if (item.allowed_split === true && result === false) {
            result = false;
        } else {
            result = true;
        }
        return result;
    }

    return <div className="product_item flex-col md:flex-row aspect-auto shadow-lg h-auto min-h-[105px] p-3 items-center md:p-4 gap-2">
        <div className="product_item--image">
            <Image
                src={src_img}
                alt="product_tab_item"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                layout='fill'
                objectFit='fill'
                className={`relative`}
            />
        </div>

        <div className="flex flex-col product_item--form justify-even">
            <div className="flex flex-row justify-between">
                {
                    is_product == false && 
                    <Link
                        rel="noopener noreferrer"
                        className={"flex w-full"}
                        title={item.name}
                        href={{ pathname: '/[...group]', query: { group: (item.url ? item.url?.split("/").filter((e: any) => e !== '') : "#") } }}
                    >
                        <span title={item?.name ?? ""} className='product_item--form--header font-bold'>{item?.name ?? ""}</span>
                    </Link>
                }
                {
                    is_product == true && <span title={item?.name ?? ""} className='product_item--form--header font-bold'>{item?.name ?? ""}</span>
                }
                
                
                <span className='product_item--form--sl  hidden lg:block'>Tồn: {checkUndefinedNumber(item?.amount)}</span>
            </div>
            <div className={'flex gap-2 mt-1 ' + (item?.attribute_res_home?.length > 1 ? "flex-row items-start" : "flex-col")}>
                {item?.attribute_res_home?.length > 1 ? <span className='font-[700] text-[13px] lg:text-[15px] 
            lg:leading-[20px] mr-1'>Thuộc tính: </span> : ""}
                {
                    item?.attribute_res_home?.map((e: any, index: any) => {
                        return <>
                            {item?.attribute_res_home?.length === 1 && <>{e.name}: </>}
                            {
                                e.values?.map((attr: any) => {
                                    return attr.key + (item?.attribute_res_home.length == (index + 1) ? "" : ", ")
                                })
                            }
                        </>
                    })
                }
            </div>
            <div className='flex flex-col gap-2 my-1'>
                <Attributes />
            </div>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-col flex-1'>
                    <div className='flex flex-row w-full gap-1 product_item--form--child'>
                        <span className='product_item--form--child'>Thuê:</span>
                        <span>{item?.rental_price ? currencyWithNoFormat(item?.rental_price) : ""}</span>
                        {/* <span>600.000</span> */}
                    </div>
                    <div className='flex flex-row w-full gap-1 product_item--form--child'>
                        <span>Mua:</span>
                        <span>{item?.price ? currencyWithNoFormat(item?.price) : ""}</span>
                        {/* <span>600.000</span> */}
                    </div>
                </div>
                <div className='flex flex-row flex-1 items-center justify-end'>
                    <InputNumber
                        //max={checkUndefinedNumber(item?.amount)} 
                        value={item.count}
                        id={item.id} disabled={is_product === false ? true : checkDisableInput()} {...props} />
                </div>

            </div>
            <span className='product_item--form--sl block lg:hidden'>Tồn: {checkUndefinedNumber(item?.amount)}</span>
        </div>
    </div>
}

const ProductTab = ({ isPopup, is_product = true, accessories, ...props }: any) => {

    return (
        <div className={"tab_product " + (isPopup ? "h-[12rem]" : "")}>
            {
                accessories?.map((item: any) => {
                    return <ProductItem is_product={is_product} attribute={props?.attribute} key={item.id} {...props} item={item} />
                })
            }
        </div>
    )
}

export default ProductTab;
