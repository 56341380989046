import ColorBox from "../Color";
import SizeBox from "../SizeBox";

interface IAttributeValuesRender {
    attributeValues: any;
    selectProductDetail: any;
    handleChangeActive: Function;
    isDisable?: boolean;
    is_combo?: boolean;
}

const AttributeValuesRender: React.FC<IAttributeValuesRender> = ({ is_combo, attributeValues, selectProductDetail, handleChangeActive, isDisable = false }) => {
    function onActive(id: number, active?: boolean) {
        if(active) {
            return active;
        }else{
            if (is_combo) {
                return selectProductDetail?.attribute_value_ids?.includes(id) ?? false;
            } else {
                return selectProductDetail?.attribute_values?.includes(id) ?? false;
            }
        }
    }

    return <>
        {
            attributeValues?.map((item: any) => {
                return <AttributeValue onActive={onActive} isDisable={isDisable} is_combo={is_combo} key={item.id} item={item} handleChangeActive={handleChangeActive} />
            })
        }
    </>
}

export const AttributeValue = ({ ...props }: any) => {

    return <div className='product_detail_right--row'>
        <span className={`
            product_detail_right--h 
            ${props?.is_combo ? "text-[#595959]" : "text-[#434343]  "}
            ${props?.is_combo ? "" : "font-[700] "}
            text-[13px] 
            lg:text-[15px] 
            lg:leading-[20px]
            z-[2]
            `}>{props?.item.name}:</span>
        <div className='product_detail_right--row--boxes'>
            {
                props?.item?.values?.map((value: any) => {
                    return props?.item.is_color ? <ColorBox
                        onChoose={() => {
                            props?.handleChangeActive(props?.item.id, value.id)
                        }}
                        key={value.id}
                        color={value.value}
                        isActive={props?.onActive(value.id, value.active)}
                        isOut={props?.is_combo ? false : props?.isDisable ? false : value.isOut}
                        isDisable={props?.isDisable}
                    />
                        : <SizeBox
                            onChoose={() => {
                                props?.handleChangeActive(props?.item.id, value.id)
                            }}
                            key={value.id}
                            size={value.key}
                            isActive={props?.onActive(value.id, value.active)}
                            isOut={props?.is_combo ? false : props?.isDisable ? false : value.isOut}
                            isDisable={props?.isDisable}
                        />
                })
            }
        </div>
    </div>
}

export default AttributeValuesRender;