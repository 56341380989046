import { IPopupOrder, IProduct } from "../../@types/interface";
import Image from 'next/image';
import { useEffect, useRef, useState } from "react";
import environment from "../../utils/environment";

const PopupSizeGuide = ({
    setIsOpen,
    url
}: any) => {

    let wrapperRef: any = useRef();
    useEffect(() => {
    }, []);

    const handleClick = (event: any) => {
        const { target } = event
        if (!wrapperRef.current.contains(target)) {
            setIsOpen(false)
        }
    }

    return (
        <div id="map_popup" className="wrap_popup" onClick={handleClick}>
            <div ref={wrapperRef} className="sizeguide_pop m-2 lg:m-o lg:max-w-[800px] flex-col relative">
                {/* <a className="close" onClick={() => { setIsOpen(false) }}>&times;</a> */}
                {url &&
                    <Image
                        src={environment.baseImageUrl + url}
                        alt="size_guide"
                        className="w-auto h-auto relative"
                        layout="fill"
                        objectFit="cover"
                    />

                }
                <Image
                    onClick={() => setIsOpen(false)}
                    src={"/asset/icon/ico_close_circle.svg"}
                    alt="close"
                    className="w-[24px] h-[24px] lg:w-[40px] lg:h-[40px] absolute top-[-10px] lg:top-[-15px] left-[96%] lg:left-[97%] z-[1000]"
                    layout="fill"
                    objectFit="cover"
                />
            </div>

        </div>
    )
}

export default PopupSizeGuide;