import Image from 'next/image';
import PopupMap from "../PopupMap";
import { useState } from 'react';

const ShopTab = ({ shops, isPopup=false, is_Product=false }: any) => {
    isPopup = isPopup ? true : false;
    const [isOpenMap, setIsOpenMap]:any = useState({
        isOpen: false,
        embed: ""
    });
    return <div id="tab_shop" className={"tab_shop gap-[16px] " + (isPopup ? "h-[12rem] pb-0" : "")}>
        <>
        <div className="notes-branch">*LƯU Ý: Thời gian làm việc các chi nhánh khác nhau. Quý khách vui lòng xem kỹ</div>
            {shops?.map((shop: any) => {
                return <div key={shop.id} className="shop_item flex flex-col gap-[2px]">
                    {/* <div className='relative'>
                        <Image
                            src="/asset/image/shop_1.svg"
                            alt="g"
                            width={600}
                            height={120}
                        />
                        <div className='shop_tab_name'>
                            {shop.name}
                        </div>
                    </div> */}
                    <h3 className='text-[#434343] text-[1rem] leading-[32px] font-[700] flex flex-row gap-3 justify-between'>
                        <span>{shop?.name}</span>
                        {(is_Product == true && (shop?.in_stock || shop?.in_stock >= 0) ? <span>{"Tồn: " + shop?.in_stock}</span> : <></>)}
                    </h3>

                    <div className='text-[14px] leading-[20px] text-[#434343] font-[400] flex flex-row gap-3 justify-between'>
                        <p>{shop?.address ? shop.address : ""} </p>
                        <div className='flex flex-row items-center'>
                            <span className='tab_shop--map' onClick={() => {
                                    setIsOpenMap({
                                        isOpen: true,
                                        embed: shop?.address_src
                                    })
                                }}>Xem bản đồ</span>
                            {/* <Image
                                src="/asset/icon/ico_green_location.svg"
                                alt="map"
                                width={16}
                                height={16}
                            /> */}
                        </div>
                    </div>
                    <div className='flex flex-wrap items-center justify-between'>
                        <div>
                        {
                            Array.isArray(shop.phone) && shop.phone?.map((item: any, index: number) => {
                                let phone = item ? item : "0986.324.594";
                                if (shop.phone.length > 1 && index !== shop.phone.length - 1) {
                                    return <>
                                        <a href={"tel:"+phone} className='my-auto tab_shop--phone text-base text-[#434343] font-[500]'>{phone}</a>
                                        <span className='my-auto px-1'> - </span>
                                    </>
                                }
                                return <a key={index} href={"tel:"+phone} className='my-auto tab_shop--phone text-base text-[#434343] font-[500]'>{phone}</a>
                            })
                        }
                        </div>
                        {
                            shop?.description &&
                            <div className="notes-branch">
                                {shop?.description}
                            </div>
                        }
                        
                        {/* <span className='my-auto tab_shop--phone text-base text-[#434343] font-[500]'>{shop?.fax ? shop?.fax : "0986.324.594"}</span>
                        <span className='my-auto tab_shop--phone text-base text-[#434343] font-[500]'>{shop?.fax ? shop?.fax : "0986.324.594"}</span> <span className='my-auto px-1'> - </span><span className='tab_shop--phone my-auto'>{shop?.phone ? shop?.phone : "0986.324.594"}</span> */}
                    </div>

                </div>
            })}
        </>
        {
            isOpenMap && isOpenMap.isOpen && <PopupMap isOpen={isOpenMap.isOpen} 
            embed={isOpenMap.embed}
            setIsOpen={(agr:any) => {
                setIsOpenMap(agr)
            }} />
        }
    </div>
}

export default ShopTab;