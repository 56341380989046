export const onChangeAttrTab = (accessories: any, main_id: any, attr: any) => {
    return accessories?.map((e: any) => {
        if (e.id === main_id) {
            e.attribute = e.attribute?.map((attrE: any) => {
                if (attrE.id === attr.id) {
                    attrE.value = attr.value
                }
                return attrE
            })
        }
        return e;
    })
}