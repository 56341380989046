import { ISizeBox } from "../../@types/interface";

const SizeBox: React.FC<ISizeBox> = ({ size, isActive, isOut, onChoose, isDisable }) => {
    return <div onClick={() => { !isOut ? onChoose() : null }}
        className={
            `
            size_box
            ${isActive ? "size_box--active" : "size_box--not_choose"}
            ${isDisable ? 'cursor-not-allowed' : ''}
        `
        }>
        {isOut && <div className="size_box--out"></div>}
        <span className="m-auto p-1">{size}</span>
    </div>
}

export default SizeBox;