import { useEffect, useState } from "react";

const DescriptionTab = ({ content, isPopup }: any) => {
    const [hasInnerText, setHasInnerText] = useState(true);
    useEffect(() => {
        let doc = document.getElementById("form_in_modal_group_content_rte-edit-view_descr");

        if (doc) {
            doc.innerHTML = content ?? " ";
        }
        
        if (doc && !doc.innerText) {
            if (content.indexOf("<img") < 0) {
                setHasInnerText(false);
            }
            // setHasInnerText(false);
        }
    }, [content])

    return <div className={"tab_description " + (isPopup ? "" : "")}>
        {/* <p id="form_in_modal_group_content_rte-edit-view" className=" hide_scrollbar"></p> */}
        <div className='lg:flex w-full hide_scrollbar' id="">
            {hasInnerText && <div id='form_in_modal_group_content' className='bg-white border-none richTextEditor e-control e-richtexteditor e-lib e-rte-toolbar-enabled w-full h-auto mt-0 mx-auto '>
                <div className=' border-none h-auto mt-0' id="form_in_modal_group_contentrte_view">
                    <div id="form_in_modal_group_content_rte-edit-view_descr" tabIndex={0} className="e-lib e-keyboard">
                        {/* {arrayProductData(typeProducts)?.find((e: any) => e.url === activeType)?.content ?? ""} */}
                    </div>
                </div>
            </div>}
        </div>
    </div>
}

export default DescriptionTab;