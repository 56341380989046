import Image from 'next/image';
import { useState } from 'react';

const InputNumber = ({ ...props }) => {
    let [value, setValue]= useState((props.value ? props.value : 1));
    const { onChange, id, max, disabled, error } = props;
    function increase() {
        if(!disabled){
            let val = value + 1;
            if (max) {
                if (val <= max && !disabled) {
                    setValue(val);
                    onChange(val, id)
                }
            } else {
                setValue(val);
                onChange(val, id)
            }
        }
    }
    function decrease() {
        if (!disabled) {
            let val = value - 1;
            if (val >= 0) {
                setValue(val);
                onChange(val, id)
            } else {
                setValue(0);
                onChange(0, id);
            }
        }

    }
    return <div className='relative z-[2]'>
        <div style={error ? { border: "1px solid #dc3545" } : { border: "1px solid #F0F0F0" }} className={"input_number " + (disabled ? " cursor-not-allowed" : "")}>
            <div onClick={decrease} className={"inner cursor-pointer w-full h-full flex " + (disabled ? "text-[#d9d9d9] cursor-not-allowed" : "")}>
                <Image
                    src={disabled ? "/asset/icon/ico_minus_input_disable.svg" : "/asset/icon/ico_minus_input.svg"}
                    alt="arrow_right"
                    width={10}
                    height={2}
                />
            </div>
            <div className="flex-1">
                {/* onChange={(e: any) => { onChange(e.target.value, id) }}  */}
                <input type="number" className={'text-center ' + (disabled ? "text-[#d9d9d9] cursor-not-allowed" : "")} value={value} />
            </div>
            <div onClick={increase} className={"inner cursor-pointer w-full h-full flex " + (disabled ? "text-[#d9d9d9] cursor-not-allowed" : "")}>
                <Image
                    src={disabled ? "/asset/icon/ico_plus_input_disable.svg" : "/asset/icon/ico_plus_input.svg"}
                    alt="arrow_right"
                    width={12}
                    height={12}
                />
            </div>
        </div>
        {
            error && <div className='absolute bottom-[-1.25rem]'>
                <p className='text-[#dc3545]'>{error}</p>
            </div>
        }
    </div>

}

export default InputNumber;

