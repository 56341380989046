import Image from 'next/image';
import { ITab } from '../../@types/interface';

const Tab: React.FC<ITab> = ({ activeIndex, setActiveIndex, isHideSP, isHideDescr }) => {

    let tabs = [
        "Sản phẩm", "Mô tả", "Thông tin chi nhánh"
    ];
    if (isHideSP) {
        tabs = ["Mô tả", "Thông tin chi nhánh"];
    }
    if (!isHideDescr) {
        tabs = tabs.filter((e: any) => e !== "Mô tả");
    }


    return <div className="product_tabs">
        <div className="product_tabs_heads ml-3 md:ml-[24px] justify-between">
            <div className="product_tabs_head">
                {
                    tabs?.map((tab: string, index: number) => {
                        return <div
                            key={index}
                            onClick={() => { setActiveIndex(index) }}
                            className={"product_tabs_head--tab " + (activeIndex === index ? "active font-[500] text-[#08979C]" : "font-[400] text-[#8c8c8c]")}>
                            <div className="pb-[16px] text-sm lg:text-base">
                                {tab}
                            </div>
                        </div>
                    })
                }
            </div>
            {/* <Image
                src={"/asset/icon/ico_question.svg"}
                alt="arrow_right"
                width={16}
                height={16}
            /> */}
        </div>
        {
            isHideSP == false && activeIndex == 0 && tabs.length > 0 &&
            <div className='product_tabs_caution pl-3 md:pl-[24px]'>
                <p className='break_2'>Lưu ý: Màu sắc có thể đậm/nhạt đôi chút do sự can thiệp ánh sáng khi chụp</p>
            </div>
        }
        
        <div className="product_tabs_content">

        </div>
    </div>
}

export default Tab;