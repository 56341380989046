import { IColorBox } from "../../@types/interface";


const ColorBox: React.FC<IColorBox> = ({ color, isActive, isOut, onChoose, isDisable }) => {

    return <div onClick={() => { isOut === false ? onChoose() : null }} style={{ backgroundColor: color }}
        className={`
        color_box
        ${isActive ? "color_box--active" : "color_box--not_choose"}
        ${isDisable ? 'cursor-not-allowed' : ''}
    `}>
        {isOut && <div className="color_box--out"></div>}
    </div>
}

export default ColorBox;